import React from 'react';
import { Link } from 'react-router-dom';
import './intelectual.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Intelectual(){
    return <div>
      <Navbar/>
      <section id="comp">
        <div className="container">
          <p id="justificar"> 
          <p className='fs-1 fw-bold text-center'> PROPRIEDADE INTELECTUAL</p> 

           <p className='fw-normal'>Na qualidade de detentores das marcas ligadas ao Grupo Editora Alphaville, nos dedicamos a proporcionar aos nossos Clientes/Usuários a compra de produtos e serviços por meio do portal da startup editoraalphaville.com.br, expectando a seriedade negocial; buscando o respeito mútuo e proporcionando a realização de sonhos, desde a publicação de uma obra à experiência positiva de compras tecnológicas e inteligentes. O Grupo Editora Alphaville não permite ofertas que violam os direitos de propriedade intelectual de marcas ou outros proprietários de direitos.</p>
          </p>
          <Link to="/app/intelectualcomp"><a>Ler mais...</a></Link>

        </div>
       </section>

       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default Intelectual;