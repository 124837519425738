import React from 'react';
import { Link } from 'react-router-dom';
import './privacidade.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Privacidade(){
    return <div>
      <Navbar/>
      <section id="comp">
        <div className="container">
          <p id="justificar"> </p>
          <p className='fs-1 fw-bold text-center'>POLÍTICA DE PRIVACIDAE</p> 

          <p className='fw-bold' id="justificar"> 1.	Lei Geral de Proteção de Dados - LGPD<p/>

           <p className='fw-normal'> A LGPD, sigla que representa a Lei Geral de Proteção de Dados, lei federal 13.709/18, tem como objetivo proteger os direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural. Em outras palavras, ela surgiu para estabelecer regras que ofereçam mais clareza sobre como coletamos, armazenamos e compartilhamos dados pessoais, como nome, endereço de e-mail, telefone e CPF. É a unificação das previsões acerca das obrigações e responsabilidades das partes no tratamento de dados pessoais e sensíveis, tanto no mundo físico quanto no digital.</p>
          </p>
          <Link to="/app/privacidadecomp"><a>Ler mais...</a></Link>
        </div>
       </section>

       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default Privacidade;