import React from 'react';
import { Link } from 'react-router-dom';
import './complace.css';


function Complace(){
  var ano = new Date().getFullYear(); 

  return <section id="complace">    
     <div className="container" id="linha">
     <div className="row" id="info">

<div className="col-lg-3 feature-box" id="logo">
      
      <a>
      <Link to="/" className="nav-link" aria-current="page" >
      <img src="/Images/logo.png" alt="key 42" height="100"/> 
      </Link>
      </a> 
   </div>

   <div className="col-lg-3 feature-box" id="col-1">
       <a><h5>Navegue</h5></a>
       <p/>
       <p><Link to="/app/sobre"><a>Sobre</a></Link></p>
       <p><Link to="/app/oquefazemos"><a>O que fazemos</a></Link></p>
       <p><Link to="/app/plataforma"><a>Valores e pilares</a></Link></p>
       <p><Link to="/app/respsocial"><a>Conceito</a></Link></p>
   </div>

   <div className="col-lg-3 feature-box" id="col-2">
       <a><h5>Contato</h5></a>
       <p/>
       <p><Link to="/app/ouvidoria"><a>Ouvidoria</a></Link></p>
       <p><Link to="/app/trabalheconosco"><a>Trabalhe conosco</a></Link></p>
   </div>

   <div className="col-lg-3 feature-box" id="col-3">
       <a><h5>Políticas</h5></a>
       <p/>
       <p><Link to="/app/compliance"><a>Compliance</a></Link></p>
       <p><Link to="/app/privacidade"><a>Privacidade</a></Link></p>
       <p><Link to="/app/intelectual"><a>Propriedade intelectual</a></Link></p>
       <p><Link to="/app/termodeuso"><a>Termo de uso</a></Link></p>
       <p><Link to="/app/transparencia"><a>Transparência</a></Link></p>
   </div>

   <p/>
   <p>________________________________________________________________________</p>
   <p className="h6">© Desenvolvido por GEA - Grupo Editora Alphaville - {ano}</p>

</div>
      </div>
</section>

}

export default Complace;