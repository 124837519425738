import React from 'react';
import { Link } from 'react-router-dom';
import './compliance.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Compliance(){
    return <div>
      <Navbar/>
      <section id="comp">
        <div className="container">
          <h1>Compliance</h1>
          <p id="justificar">No âmbito institucional e empresarial, a função do compliance é prevenir fraudes. Portanto, a equipe de Compliance Officer do Departamento de Compliance do Grupo Editora Alphaville elenca um conjunto de disciplinas a fim de cumprir e se fazer cumprir as normas legais e regulamentares, as políticas e as diretrizes estabelecidas para os seus negócios e atividades; evitando, detectando e tratando quaisquer desvios ou inconformidades que possam ocorrer. O objetivo é garantir que todas as práticas - internas e externas - desenvolvidas pela empresa estejam em conformidade com aquilo que a lei prevê, bem como os padrões e condutas internas. Desta forma, o compliance garante que o comportamento dos diferentes agentes da Editora Alphaville – que cultua valores como honra, disciplina, irmandade e ética, esteja em conformidade com as normas éticas estabelecidas, amparadas pela verdade e integridade em todos os relacionamentos.</p>

          <Link to="/app/compliancecomp"><a>Ler mais...</a></Link>


        </div>
       </section>


       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default Compliance;