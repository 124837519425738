import React from 'react';
import './termodeusocomp.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function TermodeusoComp(){
    return <div>
      <Navbar/>
      <section id="comp">
        <div className="container">
          <p id="justificar"> </p>
          <p className='fs-1 fw-bold text-center'>TERMO DE USO</p> 

          <p className='fw-bold' id="justificar"> 1.	Lei Geral de Proteção de Dados - LGPD 
          
          <p/>

           <p className='fw-normal'> A LGPD, sigla que representa a Lei Geral de Proteção de Dados, lei federal 13.709/18, tem como objetivo proteger os direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural. Em outras palavras, ela surgiu para estabelecer regras que ofereçam mais clareza sobre como coletamos, armazenamos e compartilhamos dados pessoais, como nome, endereço de e-mail, telefone e CPF. É a unificação das previsões acerca das obrigações e responsabilidades das partes no tratamento de dados pessoais e sensíveis, tanto no mundo físico quanto no digital.</p>
           <p className='fw-normal'> As obrigações e responsabilidades previstas na lei se aplicam as pessoas físicas - que realizem o tratamento de dados pessoais para fins econômicos - , e pessoas jurídicas de direito público (entidades e órgãos governamentais) e privado. Seus conceitos estão estabelecidos no artigo 5º e tem como principais personagens: o titular de dados (pessoa física viva), o controlador de dados (quem determina a forma de tratamento dos dados), o operador (quem efetua o tratamento dos dados sob as ordens do controlador) e a ANPD (Autoridade Nacional de Proteção de Dados), órgão da administração pública responsável por regular e fiscalizar o cumprimento da LGPD.</p>
           <p className='fw-normal'> A Editora Alphaville preza pela tradição do bom atendimento e transparência na forma de tratamento dos dados das pessoas com quem se relaciona na contratação de seus produtos e serviços, mantendo o compromisso de continuar respeitando e assegurando a privacidade e a proteção dos dados pessoais de quem quer que seja. Por isso, nós, representantes do Grupo Editora Alphaville, apresentamos a nossa Política de Privacidade, que tem como único objetivo fornecer a você informações claras e precisas sobre o tratamento de dados pessoais e sensíveis realizados pela Editora Alphaville ao utilizar sua plataforma, aplicativos, produtos e serviços. Após ler a íntegra desta Política de Privacidade, se porventura pairar dúvidas ou se você tiver, ainda, considerações sobre o tratamento de seus dados pessoais e sensíveis, basta acessar o nosso Núcleo de Transparência.  </p>
           <p className='fw-normal'> Todos os dados pessoais fornecidos por seus titulares, por meio da plataforma ou de aplicativos da Editora Alphaville, são usados exclusivamente para os fins descritos neste documento.</p>
           <p className='fw-bold'> 1.1. Direitos dos titulares em relação aos seus dados</p>
           <p className='fw-normal'>O titular dos dados pessoais, a qualquer momento, e de forma fácil e gratuita, pode exercer os seguintes direitos existentes na LGPD:</p>
           <p className='fw-normal'>a) Retificação/atualização de dados cadastrados: visando a prevenção à ocorrência de fraudes e para uniformizar a forma de atendimento ao titular nos pedidos de retificação/atualização de dados, algumas atualizações podem permitir que haja apenas a alteração de e-mail e telefone de contato. Neste caso, para que você faça a retificação/atualização de outros dados é necessário entrar em contato com nossas centrais de atendimento ou com o terceiro que intermediou a contratação do produto ou serviço - se for o caso, para que faça a alteração necessária em nossos sistemas.</p>
           <p className='fw-normal'>b) Preferências para recebimento de marketing: você pode selecionar a opção, conforme orientado via WhatsApp ou e-mail - se for o caso, para continuar ou não recebendo comunicações de campanhas promocionais e/ou ofertas de produtos e serviços da Editora Alphaville.</p>
           <p className='fw-normal'>1.2. Deveres dos titulares das informações</p>
           <p className='fw-normal'>Tal qual o nosso dever de transparência na qualidade de prestadores de serviços, você, na qualidade de cliente, tem o dever e a responsabilidade de compartilhar informações verdadeiras conosco e com os intermediários que, dependendo do negócio jurídico, poderão estar envolvidos na contratação dos produtos e serviços da Editora Alphaville.</p>
           <p className='fw-normal'>Quando for utilizar a nossa plataforma digital, lembre-se de sempre manter confidenciais seu login e senha e, da mesma forma, jamais compartilhe seus dados com outras pessoas. Caso o faça por alguma razão, mude a senha imediatamente. </p>
           <p className='fw-normal'>Ao firmar determinados contratos de serviços conosco, você poderá obter informações sobre o tratamento de dados pessoais, descritas nesta política de privacidade, para que possa ler previamente ou quando tiver qualquer dúvida.</p>
           <p className='fw-bold'>1.3. Serviço de privacidade</p>
           <p className='fw-normal'>Em nossos principais canais digitais, por exemplo, plataforma e aplicativo - se houver -, você tem acesso facilitado, imediato e gratuito a um serviço de privacidade. Como forma de garantir sua segurança, os acessos a determinados serviços só poderão ser feitos por áreas logadas, evitando assim o acesso indevido aos dados por terceiros, com a necessidade de confirmação de identidade e titularidade de quem está exercendo os direitos. </p>
           <p className='fw-normal'> Se você for ex-cliente e quiser aderir a determinados serviços oferecidos pelo portal editoraalphaville.com.br, para os quais é necessário estar logado, terá que fazer login para a confirmação da existência de informações em nossos sistemas e banco de dados. Se não for possível concluir o login, significa que não temos dados a seu respeito e que as informações que colocou no formulário de cadastro da Editora Alphaville já foram descartadas. </p>
           <p className='fw-normal'>Para obter acesso a outros produtos e serviços da Editora Alphaville, basta:</p>
           <p className='fw-normal'>a) Acessar www.editoraalphaville.com.br;</p>
           <p className='fw-normal'>b) Clicar em Área do Cliente;</p>
           <p className='fw-normal'>c) Fazer o cadastro/login;</p>

           <p className='fw-bold'>2. Tratamento de dados</p>
           <p className='fw-bold'>2.1. Coleta de dados</p>
           <p className='fw-normal'>De acordo com a LGPD, dados pessoais sensíveis são aqueles dados que podem causar discriminação a uma pessoa e por isto merecem maior proteção. Com fulcro na lei, dados sensíveis são aqueles que envolvem:</p>
           <p className='fw-normal'>a) Origem racial ou étnica</p>
           <p className='fw-normal'>b) Convicção religiosa</p>
           <p className='fw-normal'>c) Opinião política</p>
           <p className='fw-normal'>d) Filiação a sindicato ou a organização de caráter religioso</p>
           <p className='fw-normal'>e) Filosófico ou político</p>
           <p className='fw-normal'>f) Dado referente à saúde ou à vida sexual</p>
           <p className='fw-normal'>g) Dado genético ou biométrico, quando vinculado a uma pessoa natural</p>
           <p className='fw-normal'>Os seus dados pessoais, bem como os dados sensíveis – se for o caso -, são recebidos pela Editora Alphaville no momento da contratação de alguns de nossos produtos e serviços. Isso acontece apenas nas seguintes situações:</p>
           <p className='fw-normal'>a) Quando você preenche os formulários em nossa plataforma digital;</p>
           <p className='fw-normal'>b) Quando você entra em contato com nossas centrais de atendimento;</p>
           <p className='fw-normal'>c) Navega em nossos canais digitais.</p>
           <p className='fw-normal'>Para que haja melhor compreensão acerca dos dados pessoais e sensíveis que podem passar por nós em algum tipo de tratamento, separamos em grupos para você, conforme a seguir. </p>
           <p className='fw-bold'>2.1.1. Informações cadastrais</p>
           <p className='fw-normal'>Dados cadastrais são as informações pessoais necessárias para que possamos te identificar. Alguns exemplos: nome completo, números de documentos de identificação pessoal válida por Lei (RG, CNH, CPF, Passaporte, CRM, OAB, MTB, CREA, CRECI etc.), endereço completo, dados de contatos, como e-mails e telefones. </p>
           <p className='fw-bold'>2.1.2. Informações digitais</p>
           <p className='fw-normal'>Com o objetivo de aprimorar a experiência e promover a melhoria do desempenho da nossa plataforma digital, expandir a área de cobertura, personalizar os conteúdos e ofertas de produtos e serviços, enviar alertas ou notificações, a nosso critério poderemos coletar os seguintes dados:</p>
           <p className='fw-normal'>a)	Tráfego: geolocalização, telemetria, percursos utilizados, data e hora, tipo de dispositivo, sistema operacional, volume de dados utilizados, tipo de navegador, páginas acessadas, aplicativos instalados, dados sobre o nível/qualidade dos serviços (ocorrência de falhas, intermitências, etc), protocolos, inclusive os de internet (IP), principais funcionalidades acessadas, etc.;</p>
           <p className='fw-normal'>b)	Voz: por meio da gravação de atendimento realizados em nossas centrais de relacionamento, inclusive o SAC;</p>
           <p className='fw-normal'>c)	Confirmação de identidade dos usuários em nossa plataforma digital, utilizando, sobretudo, dados sensíveis para que a autenticação eletrônica seja feita;</p>
           <p className='fw-normal'>d)	Perfil: preferências por produtos, serviços e atividades específicas, sendo essas informações fornecidas pelo próprio usuário ou deduzível pela forma como ele utiliza nossas plataformas digitais;</p>
           <p className='fw-normal'>e)	Navegação: histórico de navegação, assim como termos de pesquisa e links acessados, nas categorias de websites/serviços/aplicativos cedidas por meio do uso de celular, computador, smart TV ou outro aparelho compatível.</p>
           <p className='fw-bold'>2.2. Forma de coleta dos dados</p>
           <p className='fw-normal'>Podemos receber e coletar seus dados pessoais e sensíveis das seguintes formas:</p>
           <p className='fw-bold'>2.2.1. Diretamente</p>
           <p className='fw-normal'>Quando você usa a nossa plataforma digital para navegar e/ou contratar nossos produtos e serviços; por meio do preenchimento de formulários ou do contato com nossas centrais de relacionamento e SAC.</p>
           <p className='fw-bold'>2.2.2. Indiretamente</p>
           <p className='fw-normal'>a) Por meio de terceiros que fazem algum tipo de intermediação na contratação ou execução dos nossos produtos e serviços, como os parceiros de negócios que, no caso, são os responsáveis pela exatidão e qualidade dos dados recebidos por nós.</p>
           <p className='fw-normal'>b) No consumo de serviços de dados de bases públicas e privadas para finalidades específicas, sempre dentro de um embasamento legal previsto na LGPD ou em outras legislações específicas.</p>
           <p className='fw-bold'>2.2.3. Automaticamente</p>
           <p className='fw-normal'>Com a navegação em nossa plataforma digital, alguns dados podem ser automaticamente coletados com a ajuda do uso de tecnologias específicas, tais como cookies; tags; beacons; tecnologias para fins de confirmação de identidade; de controle de sessão; de avaliação de performance, entre outros.</p>
           <p className='fw-bold'>2.3. Finalidade de tratamento</p>
           <p className='fw-normal'>Nós podemos tratar os dados que coletamos para:</p>
           <p className='fw-normal'>a) Ações de marketing voltadas à prospecção de novos clientes;</p>
           <p className='fw-normal'>b) Análise de perfil (profiling);</p>
           <p className='fw-normal'>c) Análises de aceitação de risco;</p>
           <p className='fw-normal'>d) Proteção ao crédito;</p>
           <p className='fw-normal'>e) Análises visando a prevenção de fraudes;</p>
           <p className='fw-normal'>f) Comercialização dos nossos produtos e serviços;</p>
           <p className='fw-normal'>g) Execução de atividades e análises pré-contratuais;</p>
           <p className='fw-normal'>h) Execução das atividades relacionadas ao produto ou serviço contratado;</p>
           <p className='fw-normal'>i) Atividades de relacionamento e atendimento ao titular;</p>
           <p className='fw-normal'>j) Concessão dos benefícios que compõem nossos produtos, serviços e políticas de recursos humanos;</p>
           <p className='fw-normal'>k) Atividades de cobrança de débitos e concessão de créditos;</p>
           <p className='fw-normal'>l) Ressarcimento de valores;</p>
           <p className='fw-normal'>m) Atividades relacionadas a ações judiciais;</p>
           <p className='fw-normal'>n) Acordos extrajudiciais para recuperação de crédito ou indenização;</p>
           <p className='fw-normal'>o) Atendimento de obrigações legais;</p>
           <p className='fw-normal'>p) Realização de pesquisas de satisfação, de perfil de consumo para aprimoramento dos nossos produtos e serviços, dentre outros;</p>
           <p className='fw-normal'>q) Realização de estudos de precificação, atuarial e estatísticos;</p>
           <p className='fw-normal'>r) Atualização e incremento cadastral;</p>
           <p className='fw-normal'>s) Pagamento de crédito e indenização a terceiros;</p>
           <p className='fw-normal'>t) Criação de métricas e indicadores em geral.</p>
           <p className='fw-normal'>Essa quantidade de finalidades de tratamento dos seus dados leva em conta a diversidade de serviços no ramo de atuação do Grupo Editora Alphaville no mercado, considerando os contratos de perícias para avaliação e vistorias técnicas de imóveis urbanos; os serviços de edição e publicação de livros com ISBN e comercialização em plataformas digitais nacionais e estrangeiras; a criação de softwares empresariais e soluções inteligentes como aplicativos e automação para WhatsApp, bem como a oferta de outros serviços correlatos. De qualquer forma, o Grupo Editora Alphaville atende prioritariamente aos requisitos da LGPD em relação à minimização de dados, transparência, qualidade e exatidão das informações que manipula, podendo, quando possível, fazer uso dos dados de maneira anônima, isto é, sem a possibilidade de associação direta ou indireta a uma determinada pessoa.</p>
           <p className='fw-bold'>2.4. Enquadramento legal</p>
           <p className='fw-normal'>O tratamento de dados que fazemos com os objetivos que informamos no item anterior acontece sempre dentro dos requisitos que estão no artigo 7º da LGPD (hipóteses de tratamento), sendo que os principais deles que embasam nosso tratamento de dados são:</p>
           <p className='fw-normal'>a)	Execução de atividades contratuais;</p>
           <p className='fw-normal'>b)	Cumprimento de obrigação legal ou regulatória;</p>
           <p className='fw-normal'>c)	Exercício regular de direitos em processos judiciais, administrativos ou arbitral - quando a empresa e o titular chamam uma entidade privada para ajudar a resolver alguma divergência;</p>
           <p className='fw-normal'>d)	Proteção do crédito;</p>
           <p className='fw-normal'>e)	Legítimo interesse do controlador ou de terceiros - em outras palavras significa que podemos usar os dados pessoais quando necessários, desde que a prática não ultrapasse seus direitos e liberdades fundamentais do titular;</p>
           <p className='fw-normal'>f)	Consentimento do titular.</p>
           <p className='fw-bold'>2.5. Compartilhamento de dados</p>
           <p className='fw-normal'>Podemos compartilhar informações com as seguintes entidades:</p>
           <p className='fw-normal'>a)	Empresas que poderão eventualmente compor o nosso grupo econômico: para a promoção dos produtos e serviços por elas comercializados e para a realização de estudos em geral;</p>
           <p className='fw-normal'>b)	Prestadores de serviços: para a execução de atividades relacionadas aos nossos produtos e serviços, a exemplo de outros portais e livrarias que comercializam nossos produtos – livros e softwares;</p>
           <p className='fw-normal'>c)	Fornecedores: na obrigatoriedade de fornecimento de produtos/livros físicos diretamente aos compradores, em casos de parcerias de vendas indiretas pela Editora Alphaville;</p>
           <p className='fw-normal'>d)	Parceiros de negócios: para concessão de benefícios, realização de estudos de viabilidade econômica etc.;</p>
           <p className='fw-normal'>e)	Autoridades públicas: para cumprimento de determinação legal ou regulatória.</p>
           <p className='fw-normal'>Os dados são compartilhados com base nas hipóteses legais, respeitando o mínimo de informações necessários para atingir o objetivo esperado, preservando a privacidade das pessoas usuárias e aplicando as melhores técnicas de proteção no tráfego desses dados.</p>
           <p className='fw-normal'>Além das hipóteses mencionadas acima, nós podemos compartilhar dados pessoais com entidades de proteção ao crédito, prevenindo situações de fraude - e também por ordem judicial e/ou determinação legal ou regulatória.</p>
           <p className='fw-bold'>2.7. Transferência internacional de dados</p>
           <p className='fw-normal'>Poderemos, eventualmente, fazer a transferência internacional de dados para processamento ou armazenamento em nossas aplicações e sistemas, com a possibilidade de o ambiente tecnológico estar alocado em diferentes lugares do mundo - se for o caso. </p>
           <p className='fw-normal'>A transferência internacional de dados é feita em pleno atendimento às exigências previstas na LGPD, dando preferência para localidades nas quais exista legislação similar ou com maior grau de proteção que a própria LGPD, ainda, localidades em que há a aplicação de cláusulas contratuais que garantam o cumprimento da norma, independentemente do local de transferência dos dados.</p>
           <p className='fw-normal'>Podemos também fazer a transferência internacional de dados quando:</p>
           <p className='fw-normal'>a)	A transferência for necessária para a cooperação jurídica internacional entre órgãos públicos de inteligência e de investigação, de acordo com as normas de direito internacional;</p>
           <p className='fw-normal'>b)	A transferência for necessária para a proteção da vida ou da integridade física dos titulares dos dados pessoais ou de terceiros;</p>
           <p className='fw-normal'>c)	A Autoridade Nacional de Proteção de Dados (ANPD) autorizar a transferência;</p>
           <p className='fw-normal'>d)	A transferência resultar em compromisso assumido em acordo de cooperação internacional;</p>
           <p className='fw-normal'>e)	A transferência for necessária para a execução de política pública ou atribuição legal do serviço público, sendo dada publicidade nos termos da lei;</p>
           <p className='fw-normal'>f)	Para o cumprimento de obrigação legal ou regulatória; ou para o exercício regular de direitos em processo judicial, administrativo ou arbitral.</p>
           <p className='fw-bold'>2.8. Retenção e exclusão de dados</p>
           <p className='fw-normal'>A Editora Alphaville permite que você tenha fácil acesso as suas informações pessoais precisas e completas e, para mantê-las atualizadas, você deverá entrar em sua conta e alterar seus ajustes. </p>
           <p className='fw-normal'>Em relação ao prazo de utilização dos seus dados pessoais, assim como o da exclusão dessas informações após a compra de um produto ou após o término das atividades contratadas, você poderá solicitar que a Editora Alphaville corrija ou elimine dados, o que deverá ser feito mediante envio de e-mail para diretoria@editoraalphaville.com.br.  A Editora Alphaville poderá recusar o processamento de solicitações de forma justificada. A solicitação será respondida em 30 (trinta) dias. </p>
           <p className='fw-normal'>Caso você queira terminar o relacionamento com a Editora Alphaville, a solicitação de exclusão deverá ser feita pelo mesmo e-mail indicado acima.</p>
           <p className='fw-normal'>Caso você não faça tal solicitação, a Editora Alphaville observa os prazos legais e regulatórios para a retenção dos dados pessoais após o término da relação, principalmente no que tange o prazo para exercício regular de direitos em ações judiciais, administrativas e arbitrais e para o cumprimento de obrigações legais, se for o caso.</p>
           <p className='fw-normal'>Os prazos podem variar de 03 a 30 anos, dependendo da legislação e regulamentação em vigor, no momento. De qualquer forma, tornamos anônimos os dados pessoais que identifiquem o titular, quando a manutenção dos dados por tempo superior ao previsto na regulamentação envolver dados sensíveis.</p>
           <p className='fw-bold'>2.9. Armazenamento de dados</p>
           <p className='fw-normal'>A Editora Alphaville faz o armazenamento e tratamento dos dados pessoais que coletamos em local seguro, sendo em seus próprios servidores físicos ou por meio da nuvem. É feito um estudo adequado para a decisiva de cada situação, visando segurança e melhoria nos processos e na entrega dos nossos produtos e serviços.</p>
           <p className='fw-bold'>2.10. Uso de cookies e como rejeita-los</p>
           <p className='fw-normal'>Os cookies de sites são arquivos criados no seu computador pelo próprio navegador todas as vezes que você acessa um site e aceita o uso deles. Eles permitem que o site seja lembrado de pedaços de sua informação de navegação, como suas informações de login e preferências de navegação, oferecendo a você uma experiência mais personalizada.</p>
           <p className='fw-normal'>O uso de cookies nos ajudam a fornecer um serviço mais personalizado e ágil, baseado em suas escolhas, o que permite analisar tendências e melhorar sua experiência em nossa plataforma digital. Quando você navegar pelo site, podem ser armazenados: 
           <p className='fw-normal'>a) Detalhes de como o você interage com a interface, tal como suas perguntas de busca, além de sua navegação;</p> 
           <p className='fw-normal'>b) Informações sobre eventos relacionados a aparelhos eletrônicos, tais como atividade do sistema e quedas de sistema;</p> 
           <p className='fw-normal'>c) A URL do website do qual você veio e o website ao qual for quando sair da Editora Alphaville;</p>
           <p className='fw-normal'></p>d) Informações sobre seus contatos em sites e/ou plataformas externas, tais como Linkedin.com, Facebook.com ou Instagram.com, caso opte por usar esses canais para convidar ou receber convite para participar da Editora Alphaville.</p>
           <p className='fw-normal'>Para rejeitar os cookies configurados pela Editora Alphaville, você não poderá salva-los em seu navegador. Isso pode acontecer por dois motivos: </p>
           <p className='fw-normal'>a)	No caso de você ter aceito os cookies anteriormente;</p>
           <p className='fw-normal'>b)	No caso de você ter visitado a página antes do controle de cookies ser instalado.</p>
           <p className='fw-normal'>Se você já possui os cookies mas quer revogá-los, é possível limpar os cookies no navegador para o site da Editora Alphaville, bem como para qualquer outro site. Ao fazer esta limpeza, na próxima visita que fizer em nossa plataforma digital você verá o banner de cookies e poderá aceitar o “uso de cookies” novamente.</p>
           <p className='fw-bold'>2.11. Segurança e proteção de dados</p>
           <p className='fw-normal'>A EditoraAlphaville.com.br preza pela proteção e privacidade das informações com as quais há qualquer tipo de tratamento. Sendo assim, por meio do nosso departamento de TI adotamos medidas, tecnologias e protocolos de segurança da informação conforme as melhores práticas de mercado, observando os padrões de qualidade das principais certificações internacionais sobre o tema, aplicado aos ambientes virtuais e físicos. </p>
           <p className='fw-bold'>3. Legislação aplicável</p>
           <p className='fw-normal'>Esta política é regida e interpretada exclusivamente de acordo com a legislação brasileira, em especial a Lei 13.709/18 – LGPD e demais normas e regulamentos brasileiros aplicáveis ao caso concreto, ou seja, quando há outras leis que, de alguma forma, tenham regras específicas sobre tratamento de dados, por exemplo, o Código de Defesa do Consumidor.</p>
           <p className='fw-bold'>4. Alterações na política de privacidade</p>
           <p className='fw-normal'>Nos reservamos o direito de modificar esta política de privacidade a qualquer momento, a manter sempre atualizada e disponível no site https://www.editoraalphaville.com.br/privacidade.</p>
           <p className='fw-normal'>Ao acessar nossas plataformas digitais, você será informado sempre que estiver disponível uma nova versão da política. De qualquer forma, recomendamos sua leitura periódica e integral do documento.</p>

          </p>


        </div>
       </section>

       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default TermodeusoComp;