import React, { useState }  from 'react';
import { Link } from 'react-router-dom';
import './home.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Home(){
    return <div>
      <Navbar/>
      <section id="banner">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <img src="Images/livro2.png"/>
                </div>

                <div className="col-lg-6">
                    <p/>
                    <p/>
                    <h2>Trasnformando sonhos em realizações.</h2>
                    <p/>
                    <p/>
                    <h4>Não importa a plataforma, não importa o momento, não importa o conteúdo. Venha ampliar horizontes: nós fazemos acontecer!</h4>
                    <p/>
                    <p/>
                    <h4></h4>
                </div>
            </div>
         </div>
       </section>

       <section id="planos">
        <div className="container">
          <div className="row">

            <div className="col-lg-4">
            <div className="card text-black bg-light mb-3">
                <img className="card-img-top" src="Images/livro.jpg" alt="key 42"/>
                <div className="card-body">
                       <h5 className="card-title">Publicações</h5>
                       <p className="card-text">Temos o modelo ideal para ampliar os limites de suas obras.</p>
                       <Link to="/#"><a>Vá além...</a></Link>
                 </div>
               </div>
            </div>


            <div className="col-lg-4">
               <div className="card text-black bg-light mb-3">
               <img className="card-img-top" src="Images/inovacao_2.jpg" alt="key 42"/>
                  <div className="card-body">
                      <h5 className="card-title">Tecnologia e Inovações</h5>
                      <p className="card-text">Amplie seu atendimento de forma rápida e acertiva. Respostas diretas e sem perder tempo.</p>
                      <Link to="/app/key42"><a>Vá além...</a></Link>
                  </div>
               </div>
            </div>

            <div className="col-lg-4">
            <div className="card text-black bg-light mb-3">
               <img className="card-img-top" src="Images/vistoria.jpg" alt="key 42"/>
               <div className="card-body">
                      <h5 className="card-title">Vistoria e Laudo Imobiliário</h5>
                      <p className="card-text">Tenha a sua disposição nosso time de especialistas sempre para te orientar e ajudar. </p>
                      <Link to="/#"><a>Vá além...</a></Link>
                  </div>
               </div>
            </div>

          </div>

         </div>
       </section>
           <Complace/>
    </div>
  }

export default Home;