import React from 'react';
import './intelectualcomp.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function IntelectualComp(){
    return <div>
      <Navbar/>
      <section id="comp">
        <div className="container">
          <p id="justificar"> 
          <p className='fs-1 fw-bold text-center'> PROPRIEDADE INTELECTUAL</p> 

           <p className='fw-normal'>Na qualidade de detentores das marcas ligadas ao Grupo Editora Alphaville, nos dedicamos a proporcionar aos nossos Clientes/Usuários a compra de produtos e serviços por meio do portal da startup editoraalphaville.com.br, expectando a seriedade negocial; buscando o respeito mútuo e proporcionando a realização de sonhos, desde a publicação de uma obra à experiência positiva de compras tecnológicas e inteligentes. O Grupo Editora Alphaville não permite ofertas que violam os direitos de propriedade intelectual de marcas ou outros proprietários de direitos.</p>
           <p className='fw-normal'>Esta página fornece, com o aparato do nosso crivo jurídico, informações sobre direitos de propriedade intelectual (PI) e preocupações comuns de PI que podemos comercializar no portal EditoraAlphaville.com.br. Para demais orientações jurídicas, você deverá consultar um advogado, sobretudo se pairar alguma dúvida específica sobre seus direitos de propriedade intelectual ou direitos de propriedade intelectual de terceiros.</p>
           <p className='fw-bold'> Direitos autorais</p> 
           <p className='fw-bold'> 1.	O que são direitos autorais?</p> 
           <p className='fw-normal'>Direitos autorais protegem obras originais como livros, vídeos, filmes, músicas, videoclipes, videogames, pinturas, software/programas de computador etc. Em geral, a lei de direitos autorais serve como incentivo para a criação de obras originais para o benefício do público. Para receber proteção de direitos autorais uma obra original deve ser criada por um autor, e deve ter alguma dose de criatividade. Se você é o autor de uma obra original, normalmente detém os direitos autorais dessa obra.</p> 
           <p className='fw-normal'>A Lei n° 9.610/1998 (“Lei de Direitos Autorais”) é a lei federal brasileira que rege materiais protegidos por direitos autorais. Em linhas gerais, ela protege o autor das criações intelectuais e as pessoas que se utilizam dessas criações, regulamentando toda e qualquer relação existente entre o criador/autor da obra e os terceiros que fazem uso dela. Antes de publicar sua obra você deverá protege-la, registrando-a junto ao órgão competente.</p> 
           <p className='fw-normal'>O registro garante a autoria ou a titularidade de uma obra intelectual, certificando que ela é de fato sua e protegendo-a de plágios ou de eventuais divulgações sem a sua autorização. O registro é aplicável apenas para direitos autorais e não a marcas comerciais ou patentes.</p> 
           <p className='fw-normal'>A proteção aos direitos autorais tem início no momento da criação de uma obra, sem a necessidade de um registro formal, certificação ou outra ação formal. Há sistemas de registro opcionais disponíveis para criadores (por exemplo, a Biblioteca Nacional; a Escola de Belas Artes da Universidade Federal do Rio de Janeiro; a Escola de Música da Universidade Federal do Rio de Janeiro; o Conselho Federal de Engenharia, Arquitetura e Agronomia), mas o registro tem apenas função documental ou administrativa.</p> 
           <p className='fw-bold'>2.	Como saber se sou o proprietário dos direitos autorais de uma ou mais imagens que pretendo usar no livro que irei publicar? </p> 
           <p className='fw-normal'>A pessoa que cria uma obra original, geralmente detém os direitos autorais desta obra. Se você tirar uma foto do seu produto, os direitos autorais da respectiva foto são seus. Assim, você pode usá-la em sua obra para vendê-la. No entanto, ao encontrar uma foto no site de outra pessoa, você não deve fazer o upload para inseri-la no livro que está escrevendo sem obter a devida permissão dessa outra pessoa.</p> 
           <p className='fw-bold'>Marca registrada</p> 
           <p className='fw-bold'>1.	O que é uma marca comercial? </p>  
           <p className='fw-normal'>Uma marca comercial é uma palavra; símbolo; desenho ou uma combinação de ambos (nome da marca e logotipo) que uma empresa usa para identificar suas mercadorias ou serviços a fim de distingui-los de outras empresas. Em outras palavras, uma marca comercial indica a origem das mercadorias ou serviços. Em termos gerais, a Lei de Propriedade Industrial ajuda a proteger o nome, o logo e a identidade, isto é, a marca de um negócio. Também ajuda a proteger uma invenção ou uma inovação que uma pessoa ou empresa desenvolveu. Ex.: O Grupo Editora Alphaville - proprietário das marcas Editora Alphaville, Mobile Now e Alphalaudos - é uma marca comercial protegida por Lei, devidamente registrada no Instituto Nacional da Propriedade Industrial, assim como a marca “Amazon” e “Magalu” que, em caráter de parceria, utilizamos para a comercialização de alguns de nossos produtos, a exemplo dos livros publicados e impressos diretamente pela Editora Alphaville e entregues pela Amazon Brasil (Amazon.com.br) e pela Magalu (magazineluiza.com.br), e aqueles que, publicados pela Editora Alphaville, são impressos e distribuídos pela Amazon Estados Unidos (Amazon.com) a todos os países do mundo. </p> 
           <p className='fw-bold'>2.	Como proteger uma marca? </p> 
           <p className='fw-normal'>O proprietário de uma marca comercial deve protege-la registrando-a na autoridade de registro específica de seu país (como o Instituto Nacional de Propriedade Industrial – INPI). </p> 
           <p className='fw-bold'>3.	Qual é o objetivo da lei de marca comercial? </p> 
           <p className='fw-normal'>O objetivo da lei de marca comercial é evitar que os consumidores se confundam quanto a quem fornece, endossa ou está associado a um produto ou serviço específico. Para evitar essa situação, o proprietário de uma marca comercial poderá impedir que outros usem sua marca sem permissão, podendo impedir também o uso de uma marca comercial similar a sua, criada propositalmente a fim de gerar confusão na mente do consumidor, com a intensão velada de vender o mesmo produto ou serviço, se valendo do prestígio da sua marca. </p> 
           <p className='fw-bold'>4.	É necessário a permissão do detentor dos direitos para usar uma marca comercial? </p> 
           <p className='fw-normal'>O fato de você não ser o proprietário da marca comercial não significa necessariamente que você não pode vender o produto de outra empresa. Se o produto for genuíno em vez de uma importação paralela ou falsificação (isto é, se o produto foi vendido no Brasil pelo próprio proprietário da marca comercial ou com o seu mero consentimento expresso ou presumido, no caso de comercialização do leitor de livros digitais iPad Mini), por vias de regra você pode usar a marca comercial para comercializar esse produto específico em sua loja. Ex.: a livraria da Editora Alphaville pode comercializar livros de outras editoras nacionais e internacionais.</p> 

           <p className='fw-bold'>O Grupo Editora Alphaville repudia veementemente a violação de propriedade intelectual, proibindo, de forma consentida a partir da ciência do Termo de Uso para a adesão de qualquer produto ou serviços, o uso, a reprodução, distribuição, exibição ou realização de qualquer outra atividade relacionada a uma criação intelectual protegida sem a autorização adequada do titular dos direitos.</p> 
          </p>

        </div>
       </section>

       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default IntelectualComp;